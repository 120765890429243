html, body {
	height:100%;
	width:100%;
	margin:0;
	padding:0;
}

.underline {
	border-bottom:2px solid;
	transition: border-bottom 1s;
}

.underline input[type="text"] {
  border: none;
}

.underline input[type="text"]:focus,
.underline input[type="text"].focus {
  outline:none;
  border-bottom: solid 3px white;
}

.border-black-t {
	outline:none;
	border:1px solid black;
}

.border-t {
	border:1px solid;
	transition:  border 1s;
}

.border-left-t {
	border-left:1px solid;
	transition:  border-left 1s;
}

.border-right-t {
	border-right:1px solid;
	transition:  border-right 1s;
}

.border-top-t {
	border-top:1px solid;
	transition: border-top 1s;
}

.border-bottom-t {
	border-bottom:1px solid;
	transition: border-bottom 1s;
}

.black-t {
  border-color:black;
}

.white-t {
	border-color:white;
}

.nav-border {
  border-bottom: solid 4px rgb(0, 0, 90);
}

.nav-raised {
  box-shadow: 0 10px 20px #333;
}
