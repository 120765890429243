/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2014 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      exljbris Font Foundry
 * License URL: http://www.fontspring.com/licenses/exljbris/webfont
 *
 *
 */

@font-face {
    font-family: 'museo300';
    src: url('./Museo300-Regular-webfont.otf') format("opentype");
    src: url('./Museo300-Regular-webfont.eot'), 
         url('./Museo300-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./Museo300-Regular-webfont.woff2') format('woff2'),
         url('./Museo300-Regular-webfont.woff') format('woff'),
         url('./Museo300-Regular-webfont.ttf') format('truetype'),
         url('./Museo300-Regular-webfont.svg#museo300') format('svg');
    font-weight: normal;
    font-style: normal;

}




.museo {
	font-family: 'museo300', sans-serif;
}



